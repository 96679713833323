import React, { useState } from 'react';
import './FormVehiculos.css';
import { createVehiculo } from '../../services/VehiculosService';

const FormVehiculos = () => {
  const [vehiculo, setVehiculo] = useState({
    marca: '',
    modelo: '',
    vehiculo: '',
    cantidad: 1,
    valor: '',
    transito: '',
    valor_soat: '',
    valor_impuestos: '',
    valor_gestion: '',
    valor_matricula: '',
    tipo_descuento: 'Ninguno',
    descuento: 0,
    valor_prenda: 0,
  });

  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setVehiculo({ ...vehiculo, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await createVehiculo(vehiculo);
      // Limpiar el formulario y mostrar mensaje de éxito
      setVehiculo({
        marca: '',
        modelo: '',
        vehiculo: '',
        cantidad: 1,
        valor: '',
        transito: '',
        valor_soat: '',
        valor_impuestos: '',
        valor_gestion: '',
        valor_matricula: '',
        tipo_descuento: 'Ninguno',
        descuento: 0,
        valor_prenda: 0,
      });
      setError('Vehículo creado correctamente');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="form-vehiculos">
      <h2>Registrar Vehículo</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="marca">Marca:</label>
          <input
            type="text"
            id="marca"
            name="marca"
            value={vehiculo.marca}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="modelo">Modelo:</label>
          <input
            type="number"
            id="modelo"
            name="modelo"
            value={vehiculo.modelo}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="vehiculo">Vehículo:</label>
          <input
            type="text"
            id="vehiculo"
            name="vehiculo"
            value={vehiculo.vehiculo}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="cantidad">Cantidad:</label>
          <input
            type="number"
            id="cantidad"
            name="cantidad"
            value={vehiculo.cantidad}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="valor">Valor:</label>
          <input
            type="number"
            id="valor"
            name="valor"
            value={vehiculo.valor}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="transito">Tránsito:</label>
          <input
            type="text"
            id="transito"
            name="transito"
            value={vehiculo.transito}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="valor_soat">Valor SOAT:</label>
          <input
            type="number"
            id="valor_soat"
            name="valor_soat"
            value={vehiculo.valor_soat}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="valor_impuestos">Valor Impuestos:</label>
          <input
            type="number"
            id="valor_impuestos"
            name="valor_impuestos"
            value={vehiculo.valor_impuestos}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="valor_gestion">Valor Gestión:</label>
          <input
            type="number"
            id="valor_gestion"
            name="valor_gestion"
            value={vehiculo.valor_gestion}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="valor_matricula">Valor Matrícula:</label>
          <input
            type="number"
            id="valor_matricula"
            name="valor_matricula"
            value={vehiculo.valor_matricula}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="tipo_descuento">Tipo Descuento:</label>
          <select
            id="tipo_descuento"
            name="tipo_descuento"
            value={vehiculo.tipo_descuento}
            onChange={handleChange}
          >
            <option value="Ninguno">Ninguno</option>
            {/* Agrega más opciones de descuento según sea necesario */}
          </select>
        </div>
        <div>
          <label htmlFor="descuento">Descuento:</label>
          <input
            type="number"
            id="descuento"
            name="descuento"
            value={vehiculo.descuento}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="valor_prenda">Valor Prenda:</label>
          <input
            type="number"
            id="valor_prenda"
            name="valor_prenda"
            value={vehiculo.valor_prenda}
            onChange={handleChange}
          />
        </div>
        <button type="submit">Registrar</button>
      </form>
    </div>
  );
};

export default FormVehiculos;