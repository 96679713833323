import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FormVehiculos from './components/FormVehiculos/FormVehiculos';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/form-vehiculos" element={<FormVehiculos />} />
            </Routes>
        </Router>
    );
};

export default App;