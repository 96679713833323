const baseUrl = 'https://virgilioback.appengine.lucia.com.co/v1/vehiculos/';

export const createVehiculo = async (vehiculo) => {
  try {
    const response = await fetch(baseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(vehiculo),
    });
    const data = await response.json();
    if (!response.ok) throw new Error(data.message || "Algo salió mal");
    return data;
  } catch (error) {
    throw error;
  }
};